import moment from 'moment'

import {Lesson, StarStatus} from '@ui/schemes/ro/lesson'
import {StarInfo, StarType} from '@ui/schemes/ro/star-info'

const getLessonStarStatus = (today: string, lesson: Lesson, starInfo: StarInfo): StarStatus => {
  const plannedIn = lesson.lesson_student.planned_at__in

  if (plannedIn > 0) return StarStatus.Soon
  if (!starInfo) return StarStatus.Soon
  if (starInfo.amount > 0) return StarStatus.Got

  const startAt = lesson.lesson_student.start_at
  return starInfo.date === today && !startAt ? StarStatus.Available : StarStatus.Missed
}

const getHomeworkStarStatus = (today: string, lesson: Lesson, starInfo: StarInfo): StarStatus => {
  const availableIn = lesson.lesson_student.available_homework_start__in

  if (availableIn > 0) return StarStatus.Soon
  if (!starInfo) return StarStatus.Soon
  if (starInfo.amount > 0) return StarStatus.Got

  const endAt = lesson.lesson_student.homework_end_at
  return starInfo.date === today && !endAt ? StarStatus.Available : StarStatus.Missed
}

const getTrainerStarStatus = (today: string, starInfo: StarInfo): StarStatus => {
  if (!starInfo) return StarStatus.Soon
  if (starInfo.amount > 0) return StarStatus.Got

  return starInfo.date === today ? StarStatus.Available : moment(today).isAfter(starInfo.date) ? StarStatus.Missed : StarStatus.Soon
}

export const extractLessonStarInfo = (starInfos: StarInfo[], today: string) => (lesson: Lesson) => {
  if (!lesson.lesson_student) return lesson

  const lectureStarInfo = starInfos.find(s => s.star_type === StarType.Lecture && s.date === lesson.lesson_student.planned_independent_edu_day)
  const homeworkStarInfo = starInfos.find(s => s.star_type === StarType.Homework && s.date === lesson.lesson_student.homework_independent_edu_day)
  const trainerStarInfo = starInfos.find(s => s.star_type === StarType.Trainer && s.date === lesson.lesson_student.planned_independent_edu_day)

  const gainedStars = (
    (lectureStarInfo?.amount > 0 ? 1 : 0) +
    (homeworkStarInfo?.amount > 0 ? 1 : 0) +
    (trainerStarInfo?.amount > 0 ? 1 : 0)
  )

  let commonStarStatus

  if (gainedStars === 3) {
    commonStarStatus = StarStatus.Got
  } else if (lectureStarInfo && lectureStarInfo.date === today) {
    commonStarStatus = StarStatus.Available
  } else if (lectureStarInfo && moment(lectureStarInfo.date).isAfter(today, 'day')) {
    commonStarStatus = StarStatus.Soon
  } else {
    commonStarStatus = StarStatus.Missed
  }

  let lectureStarStatus = getLessonStarStatus(today, lesson, lectureStarInfo)
  let homeworkStarStatus = getHomeworkStarStatus(today, lesson, homeworkStarInfo)
  let trainerStarStatus = getTrainerStarStatus(today, trainerStarInfo)

  lesson.lesson_student = {
    ...lesson.lesson_student,
    lectureStarInfo,
    homeworkStarInfo,
    trainerStarInfo,
    gainedStars,
    commonStarStatus,
    lectureStarStatus,
    homeworkStarStatus,
    trainerStarStatus,
  }

  return lesson
}
