export interface StarInfo {
  id: number
  date: string
  star_type: StarType
  amount: number
  read: boolean
}

export enum StarType {
  Lecture = 0,
  Homework = 1,
  Trainer = 2,
}
