import {StarInfo} from '@ui/schemes/ro/star-info'
import {StreamTest} from '@ui/schemes/ro/stream-test'

export interface Lesson {
  id: number;
  name: string;
  edustream_subject: number;
  edustream_subject_id: number;
  date: string;
  order: number;
  available_homework_start: string;
  homework_deadline: string;
  status: number;
  broadcast_url: string;
  record_url: string;
  available_homework_start_in: number;
  date_in: number;
  homework_deadline_in: number;
  lesson_student?: {
    id: number
    planned_at: string
    start_at: string
    end_at: string
    homework_start_at: string
    homework_end_at: string
    homework_result: number
    available_homework_start: string
    homework_deadline: string
    planned_at__in: number
    available_homework_start__in: number
    homework_deadline__in: number
    homework_independent_edu_day: string
    planned_independent_edu_day: string
    lectureStarInfo?: StarInfo // custom
    homeworkStarInfo?: StarInfo // custom
    trainerStarInfo?: StarInfo // custom
    gainedStars?: number // custom
    commonStarStatus?: StarStatus // custom
    lectureStarStatus?: StarStatus // custom
    homeworkStarStatus?: StarStatus // custom
    trainerStarStatus?: StarStatus // custom
  }
  test?: StreamTest
}

export enum StarStatus {
  Soon = 'soon',
  Available = 'available',
  Got = 'got',
  Missed = 'missed',
}

export interface LessonMaterialModel {
  id: number;
  media: {
    id: string;
    file: string;
    name: string;
  };
  media_id: string;
  lesson: number;
  file_size: string;
  extension?: string; //custom
  color?: string; //custom
}

export const LESSON_STATUS_NOT_STARTED = 0
export const LESSON_STATUS_STARTED = 1
export const LESSON_STATUS_FINISHED = 2

export const LESSON_STATUS_CODE_MAP: { [status: number]: string } = {
  [LESSON_STATUS_NOT_STARTED]: 'not_started',
  [LESSON_STATUS_STARTED]: 'started',
  [LESSON_STATUS_FINISHED]: 'finished',
}
