import {LessonMaterialModel} from '@ui/schemes/ro/lesson'

const getUrlExtension = (url: string): string => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

function getExtensionColor(type: string): string {
  switch (type) {
    case 'png':
      return '#FA3F3F'
    case 'jpg':
      return '#FA3F3F'
    case 'jpeg':
      return '#FA3F3F'
    case 'docx':
      return '#3F5DFA'
    case 'doc':
      return '#3F5DFA'
    case 'pdf':
      return '#3F5DFA'
    case 'pptx':
      return '3FFA5D'
    default:
      return '#FA3F3F'
  }
}

export const processMaterials = (materials: LessonMaterialModel[]): LessonMaterialModel[] => {
  return materials.map(m => {
    m.extension = getUrlExtension(m.media.file)
    m.color = getExtensionColor(m.extension)
    return m
  })
}
