import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, exhaustMap, firstValueFrom, map, Observable, shareReplay, take} from 'rxjs'

import {env} from '@ui/env'
import {EduStream, EduStreamSubject} from '@ui/schemes/ro/edu-stream'
import {List} from '@ui/schemes/ro/list'
import {Lesson, LessonMaterialModel} from '@ui/schemes/ro/lesson'
import {Module} from '@ui/schemes/ro/module'
import {Homework, HomeworkAnswer, HomeworkQuestion, HomeworkSolutionModel} from '@ui/schemes/ro/homework'
import {HomeworkToday} from '@ui/schemes/ro/homework-today'
import {IndepModule, IndepSubject} from '@ui/schemes/ro/indep-subject'
import {processMaterials} from '@ui/utils/process-materials'
import {StarInfo, StarType} from '@ui/schemes/ro/star-info'
import {EduCurrent} from '@ui/schemes/ro/edu-current'
import {IndepSubjectInfo} from '@ui/schemes/ro/indep-subject-info'
import {preprocessIndepSubjects} from '@ui/utils/preprocess-indep-subjects'
import {extractLessonStarInfo} from '@ui/utils/extract-lesson-star-infos'
import {FriendConn} from '@ui/schemes/ro/friend-conn'
import {Profile} from '@ui/schemes/ro/profile'
import {StreamTest} from '@ui/schemes/ro/stream-test'
import {HTTP_OPTIONS_SHOW_LOADER, HTTP_PARAM_FETCHING_MEDIA, HTTP_PARAM_SHOW_LOADER, HTTP_PARAM_SKIP_ERROR_HANDLE} from '@ui/constants/constants'

@Injectable({
  providedIn: 'root',
})
export class EduService {

  private _refreshOrdinSubjects$ = new BehaviorSubject<void>(null)

  private _refreshIndepSubjects$ = new BehaviorSubject<void>(null)

  private _ordinSubjects$: Observable<EduStreamSubject[]> = this.http
    .get<EduStreamSubject[]>(this.getEduUrl('edustreams/subjects'))
    .pipe(shareReplay(1))

  private _indepSubjects$: Observable<IndepSubject[]> = this._refreshIndepSubjects$.pipe(
    exhaustMap(() => this.listIndepSubjects()),
    shareReplay(1),
  )

  constructor(
    private http: HttpClient,
  ) {
  }

  get ordinSubjects$(): Observable<EduStreamSubject[]> {
    return this._ordinSubjects$.pipe(take(1))
  }

  get indepSubjects$(): Observable<IndepSubject[]> {
    return this._indepSubjects$.pipe(take(1))
  }

  get indepToday(): Promise<string> {
    return firstValueFrom(this.indepSubjects$.pipe(map(subjects => subjects[0]?.current_edu_day)))
  }

  refreshOrdinSubjects$(): void {
    this._refreshOrdinSubjects$.next()
  }

  refreshIndepSubjects(): void {
    this._refreshIndepSubjects$.next()
  }

  listIndepSubjects(): Observable<IndepSubject[]> {
    return this.http.get<IndepSubject[]>(this.getEduUrl('edustreams/independent/subjects')).pipe(map(preprocessIndepSubjects))
  }

  getOrdinSubjectStreamId(subjectId: number): Observable<number> {
    return this.ordinSubjects$.pipe(
      map(subjects => {
        const subject = subjects.find(obj => +obj.edustream_subject.id === +subjectId || +obj.id === +subjectId)
        return subject.edustream_subject.edustream
      }),
    )
  }

  getIndepSubjectStreamId(subjectId: number): Observable<number> {
    return this.indepSubjects$.pipe(
      map(subjects => {
        let module: IndepModule = null

        subjects.find(s => {
          module = s.student_edustream_subjects.find(m => m.edustream_subject.id === subjectId)
          return module
        })

        return module ? module.edustream_subject.edustream.id : null
      }),
    )
  }

  preprocessIndepLessons(lessons: Lesson[], offset: number, tests: StreamTest[], starInfos: StarInfo[], ): Promise<Lesson[]> {
    return this.extractLessonStarInfos(this.insertTestsIntoIndepLessons(lessons, offset, tests), starInfos)
  }

  insertTestsIntoIndepLessons(lessons: Lesson[], offset: number, tests: StreamTest[]): Lesson[] {
    const map = new Map<number, Partial<Lesson>[]> // (test_type) to (lesson

    tests.forEach(t => {
      const n = t.available_after_lessons

      let arr = map.get(n)
      if (!arr) arr = []

      map.set(n, [...arr, {test: t}])
    })

    return lessons.reduce((acc, curr, i) => {
      acc.push(curr)

      for (let n of map.keys()) {
        if (i + offset + 1 === n) acc.push(...map.get(n))
      }

      return acc
    }, [])
  }

  async extractLessonStarInfos(lessons: Lesson[], starInfos: StarInfo[]): Promise<Lesson[]> {
    const today = await this.indepToday
    return lessons.map(extractLessonStarInfo(starInfos, today))
  }

  // url getters

  getEduUrl(branch: any): string {
    return `${env.apiHost}/api/v2/courses/edu/${branch}/`
  }

  getStreamUrl(streamId: number, branch: any): string {
    return `${this.getEduUrl(`edustreams/${streamId}/${branch}`)}`
  }

  getSubjectUrl(streamId: number, subjectId: number, branch: string): any {
    return this.getStreamUrl(streamId, `subjects/${subjectId}/${branch}`)
  }

  getLessonUrl(streamId: number, subjectId: number, lessonId: number, branch: any): string {
    return this.getSubjectUrl(streamId, subjectId, `lessons/${lessonId}/${branch}`)
  }

  getHwUrl(streamId: number, subjectId: number, lessonId: number, branch: any): string {
    return this.getLessonUrl(streamId, subjectId, lessonId, `homework/${branch}`)
  }

  getHwTestUrl(streamId: number, subjectId: number, lessonId: number, branch: any): string {
    return this.getHwUrl(streamId, subjectId, lessonId, `questions/${branch}`)
  }

  // http requests

  getSubjectObject(subjectId: number): Observable<EduStreamSubject> {
    return this.ordinSubjects$.pipe(map((subjects) => {
      return subjects.find(obj => +obj.edustream_subject.id === +subjectId || +obj.id === +subjectId)
    }))
  }

  listFriendsConns(params: any): Observable<FriendConn[]> {
    return this.http.get<FriendConn[]>(this.getEduUrl(`friends_connections`), {params})
  }

  findFriendConn(payload: any): Observable<Profile> {
    const params = {[HTTP_PARAM_SHOW_LOADER]: true, [HTTP_PARAM_SKIP_ERROR_HANDLE]: true}
    return this.http.post<Profile>(this.getEduUrl(`friends_connections/find_friend`), payload, {params})
  }

  sendFriendConnReq(payload: any): Observable<FriendConn> {
    return this.http.post<FriendConn>(this.getEduUrl(`friends_connections/send_request`), payload, HTTP_OPTIONS_SHOW_LOADER)
  }

  acceptFriendConnReq(id: number): Observable<FriendConn> {
    return this.http.post<FriendConn>(this.getEduUrl(`friends_connections/${id}/accept`), null, HTTP_OPTIONS_SHOW_LOADER)
  }

  declineFriendConnReq(id: number): Observable<FriendConn> {
    return this.http.post<FriendConn>(this.getEduUrl(`friends_connections/${id}/decline`), null, HTTP_OPTIONS_SHOW_LOADER)
  }

  getFriendConnStat(id: number): Observable<IndepModule[]> {
    return this.http.get<IndepModule[]>(this.getEduUrl(`friends_connections/${id}/friend_stat`))
  }

  stopFriendConn(id: number): Observable<FriendConn> {
    return this.http.post<FriendConn>(this.getEduUrl(`friends_connections/${id}/stop`), null, HTTP_OPTIONS_SHOW_LOADER)
  }

  setFriendConnAsViewed(id: number): Observable<void> {
    const params = {[HTTP_PARAM_SKIP_ERROR_HANDLE]: true}
    return this.http.post<void>(this.getEduUrl(`friends_connections/${id}/viewed`), null, {params})
  }

  getCurrent(): Observable<EduCurrent> {
    return this.http.get<EduCurrent>(this.getEduUrl(`current`))
  }

  listIndepSubjectInfos(): Observable<IndepSubjectInfo[]> {
    return this.http.get<IndepSubjectInfo[]>(this.getEduUrl(`independent_subjects`))
  }

  listStreams(): Observable<EduStream[]> {
    return this.http.get<EduStream[]>(this.getEduUrl(`edustreams`))
  }

  listStarInfos(masterSubjectId: number, moduleId: number, params?: any): Observable<StarInfo[]> {
    return this.http.get<StarInfo[]>(this.getEduUrl(`edustreams/independent/subjects/${masterSubjectId}/student_edustream_subjects/${moduleId}/stars`), {params})
  }

  readStarInfo(masterSubjectId: number, moduleId: number, starInfoId: number): Observable<void> {
    return this.http.post<void>(this.getEduUrl(`edustreams/independent/subjects/${masterSubjectId}/student_edustream_subjects/${moduleId}/stars/${starInfoId}/read`), null)
  }

  private async isTodayStarGainActivity(date: string): Promise<boolean> {
    const today = await this.indepToday
    return today === date
  }

  private async getTodayStarInfo(masterSubjectId: number, moduleId: number, starType: StarType): Promise<StarInfo> {
    const date = await this.indepToday
    const request = this.listStarInfos(masterSubjectId, moduleId, {date, [HTTP_PARAM_SHOW_LOADER]: true})
    const starInfos = await firstValueFrom(request)

    return starInfos.find(s => s.star_type === starType)
  }

  private async showStarAvailability(masterSubjectId: number, moduleId: number, date: string, starType: StarType): Promise<boolean> {
    const isToday = await this.isTodayStarGainActivity(date)
    if (!isToday) return false

    const starInfo = await this.getTodayStarInfo(masterSubjectId, moduleId, starType)
    if (!starInfo) return false

    return starInfo.amount === 0
  }

  async showLessonStarAvailability(masterSubjectId: number, moduleId: number, lesson: Lesson): Promise<boolean> {
    return this.showStarAvailability(masterSubjectId, moduleId, lesson.lesson_student.planned_independent_edu_day, StarType.Lecture)
  }

  async showHomeworkStarAvailability(masterSubjectId: number, moduleId: number, lesson: Lesson): Promise<boolean> {
    return this.showStarAvailability(masterSubjectId, moduleId, lesson.lesson_student.homework_independent_edu_day, StarType.Homework)
  }

  private async showStarGain(masterSubjectId: number, moduleId: number, starType: StarType): Promise<StarInfo> {
    const starInfo = await this.getTodayStarInfo(masterSubjectId, moduleId, starType)
    return starInfo ? (starInfo.read === false ? starInfo : null) : null
  }

  async showLessonStarGain(masterSubjectId: number, moduleId: number, lesson: Lesson): Promise<StarInfo> {
    const isToday = await this.isTodayStarGainActivity(lesson.lesson_student.planned_independent_edu_day)
    return isToday ? this.showStarGain(masterSubjectId, moduleId, StarType.Lecture) : null
  }

  async showHomeworkStarGain(masterSubjectId: number, moduleId: number, lesson: Lesson): Promise<StarInfo> {
    const isToday = await this.isTodayStarGainActivity(lesson.lesson_student.homework_independent_edu_day)
    return isToday ? this.showStarGain(masterSubjectId, moduleId, StarType.Homework) : null
  }

  async showTrainerStarGain(masterSubjectId: number, moduleId: number): Promise<StarInfo> {
    return this.showStarGain(masterSubjectId, moduleId, StarType.Trainer)
  }

  listToday(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(this.getEduUrl(`edustreams/today`))
  }

  listHomeworksToday(): Observable<HomeworkToday[]> {
    return this.http.get<HomeworkToday[]>(this.getEduUrl(`edustreams/homeworks`))
  }

  listTests(streamId: number, subjectId: number) {
    return this.http.get<StreamTest[]>(this.getSubjectUrl(streamId, subjectId, `tests`))
  }

  startTest(streamId: number, subjectId: number, testId: number) {
    return this.http.post<StreamTest>(this.getSubjectUrl(streamId, subjectId, `tests/${testId}/start`), null)
  }

  listLessons(streamId: number, subjectId: number, params: any): Observable<List<Lesson>> {
    return this.http.get<List<Lesson>>(this.getSubjectUrl(streamId, subjectId, `lessons`), {params})
  }

  getLesson(streamId: number, subjectId: number, lessonId: number): Observable<Lesson> {
    return this.http.get<Lesson>(this.getSubjectUrl(streamId, subjectId, `lessons/${lessonId}`), {params: {[HTTP_PARAM_SHOW_LOADER]: true}})
  }

  joinLesson(streamId: number, subjectId: number, lessonId: number): Observable<Lesson> {
    return this.http.post<Lesson>(this.getLessonUrl(streamId, subjectId, lessonId, `join`), null, {params: {[HTTP_PARAM_SHOW_LOADER]: true}})
  }

  listModules(streamId: number, subjectId: number, lessonId: number): Observable<Module[]> {
    return this.http.get<Module[]>(this.getLessonUrl(streamId, subjectId, lessonId, `modules`))
  }

  listMaterials(streamId: number, subjectId: number, lessonId: number): Observable<LessonMaterialModel[]> {
    return this.http.get<LessonMaterialModel[]>(this.getLessonUrl(streamId, subjectId, lessonId, `media`)).pipe(map(processMaterials))
  }

  getHw(streamId: number, subjectId: number, lessonId: number): Observable<Homework> {
    return this.http.get<Homework>(this.getLessonUrl(streamId, subjectId, lessonId, `homework`))
  }

  startHw(streamId: number, subjectId: number, lessonId: number): Observable<Homework> {
    return this.http.post<Homework>(this.getHwUrl(streamId, subjectId, lessonId, `start`), null)
  }

  finishHw(streamId: number, subjectId: number, lessonId: number): Observable<Homework> {
    return this.http.post<Homework>(this.getHwUrl(streamId, subjectId, lessonId, `finish`), null)
  }

  listHwQuestions(streamId: number, subjectId: number, lessonId: number): Observable<HomeworkQuestion[]> {
    return this.http.get<HomeworkQuestion[]>(this.getHwUrl(streamId, subjectId, lessonId, `questions`))
  }

  getHwQuestion(streamId: number, subjectId: number, lessonId: number, questionId: number): Observable<HomeworkQuestion> {
    return this.http.get<HomeworkQuestion>(this.getHwTestUrl(streamId, subjectId, lessonId, questionId))
  }

  listHwAnswers(streamId: number, subjectId: number, lessonId: number): Observable<HomeworkAnswer[]> {
    return this.http.get<HomeworkAnswer[]>(this.getHwTestUrl(streamId, subjectId, lessonId, `answers`))
  }

  sendHwAnswer(streamId: number, subjectId: number, lessonId: number, questionId: number, payload: any, params: any): Observable<HomeworkAnswer> {
    return this.http.post<HomeworkAnswer>(this.getHwTestUrl(streamId, subjectId, lessonId, `${questionId}/answer`), payload, {params})
  }

  useHwSolution(streamId: number, subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.http.post<HomeworkSolutionModel[]>(this.getHwTestUrl(streamId, subjectId, lessonId, `${questionId}/use_solution`), null)
  }

  listHwSolutions(streamId: number, subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.http.get<HomeworkSolutionModel[]>(this.getHwTestUrl(streamId, subjectId, lessonId, `${questionId}/solutions`))
  }

  listHwAllSolutions(streamId: number, subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.http.get<HomeworkSolutionModel[]>(this.getHwTestUrl(streamId, subjectId, lessonId, `${questionId}/all_solutions`))
  }

  getFile(link: string): Observable<any> {
    return this.http.head(link, {
      observe: 'response',
      responseType: 'blob',
      params: {
        [HTTP_PARAM_FETCHING_MEDIA]: true,
        [HTTP_PARAM_SKIP_ERROR_HANDLE]: true,
      },
    })
  }
}
